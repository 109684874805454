<template>
  <div>

    <div class="w-full h-full border rounded-md" :class="{ 'border-primary': !error, 'border-error': error, 'shadow-secondary-round': isFocus, 'hover:shadow-primary-sm': !isFocus }" v-if="editor" @click="setFocused" v-click-outside="setFocusedFalse" >
      <div class="flex flex-wrap">
        <button @click="editor.chain().focus().toggleBold().run()" title="Bold" class="p-2 rounded-lg" :class="{ 'is-active': editor.isActive('bold') }">
          <span class="text-xl font-black icon-bold hover:font-bold hover:text-primary" :class="{ 'text-primary': editor.isActive('bold') }"></span>
        </button>
        <button @click="editor.chain().focus().toggleItalic().run()" title="Italic" class="p-2 rounded-lg" :class="{ 'is-active': editor.isActive('italic') }">
          <span class="text-xl font-black icon-italic hover:font-bold hover:text-primary " :class="{ 'text-primary': editor.isActive('italic') }"></span>
        </button>
        <button @click="editor.chain().focus().toggleUnderline().run()" title="Underline" class="p-2 rounded-lg" :class="{ 'is-active': editor.isActive('underline') }">
          <span class="text-xl font-black icon-underline hover:font-bold hover:text-primary" :class="{ 'text-primary': editor.isActive('underline') }"></span>
        </button>
        <button @click="editor.chain().focus().toggleBulletList().run()" title="Bullet list" class="p-2 rounded-lg" :class="{ 'is-active': editor.isActive('bulletList') }">
          <span class="text-xl font-black icon-list hover:font-bold hover:text-primary" :class="{ 'text-primary': editor.isActive('bulletList')}"></span>
        </button>
        <button @click="editor.chain().focus().toggleOrderedList().run()" title="Numbered list" class="p-2 rounded-lg" :class="{ 'is-active': editor.isActive('orderedList') }">
          <span class="text-xl font-black solutip-icon-text-number-list hover:font-bold hover:text-primary" :class="{ 'text-primary': editor.isActive('orderedList')}"></span>
        </button>
        <div class="relative" title="Heading" v-click-outside="closeToggleParagraph">
          <button @click="changeToggleParagraph" class="flex items-center p-2 text-sm text-gray hover:text-primary" >
            {{heading}} <span class="px-1 icon-chevron-down text-gray hover:text-gray-dark"></span>
          </button>
          <ul v-show="toggleParagraph" @click="changeToggleParagraph" class="absolute z-30 bg-white rounded-lg shadow-primary-sm ">
            <li @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }" class="w-32 px-4 py-2 text-base font-medium cursor-pointer text-gray hover:bg-primary-lightest">Heading 1</li>
            <li @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }" class="w-32 px-4 py-2 text-sm font-medium cursor-pointer text-gray hover:bg-primary-lightest">Heading 2</li>
            <li @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }" class="w-32 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Heading 3</li>
            <li @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }" class="w-32 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Paragraph</li>
          </ul>
        </div>
        <button class="p-2" @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()" title="Insert Table">
          <span class="text-xl font-black solutip-icon-table-add hover:text-primary"></span>
        </button>
        <div class="relative" title="Insert Column or Row" v-click-outside="closeToggleTableAdd">
          <button class="p-2" @click="changeToggleTableAdd">
            <span class="text-xl font-black solutip-icon-table-settings hover:font-bold hover:text-primary"></span>
          </button>
          <ul v-show="toggleTableAdd" @click="changeToggleTableAdd" class="absolute z-30 bg-white rounded-lg shadow-primary-sm ">
            <li @click="editor.chain().focus().addColumnBefore().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Add Column Before</li>
            <li @click="editor.chain().focus().addColumnAfter().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Add Column After</li>
            <li @click="editor.chain().focus().addRowBefore().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Add Row Before</li>
            <li @click="editor.chain().focus().addRowAfter().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Add Row After</li>
            <li @click="editor.chain().focus().deleteColumn().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Delete Column</li>
            <li @click="editor.chain().focus().deleteRow().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Delete Row</li>
            <li @click="editor.chain().focus().deleteTable().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Delete Table</li>
            <li @click="editor.chain().focus().mergeOrSplit().run()" class="w-48 px-4 py-2 text-xs font-medium cursor-pointer text-gray hover:bg-primary-lightest">Merge Or Split</li>
          </ul>
        </div>
      </div>
      <editor-content :editor="editor" class="height-tiptap-input text-gray-darkest"/>
    </div>
    <div
      v-if="this.$slots['message'] != undefined"
      class="mt-1 text-xs"
      :class="[{ 'text-error': error }]"
    >
      <slot name="message"></slot>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import vClickOutside from 'v-click-outside'
import CharacterCount from '@tiptap/extension-character-count'

export default {
  directives: {
      clickOutside: vClickOutside.directive
    },
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    error: Boolean,
    limit: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      editor: null,
      toggleParagraph: false,
      toggleTableAdd: false,
      toggleTableDelete: false,
      toggleTableTools: false,
      isFocus: false,
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value
      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
  computed: {
    heading() {
      if (this.editor.isActive('heading', { level: 1 })) {
        return 'Heading 1'
      } else if (this.editor.isActive('heading', { level: 2 })) {
        return 'Heading 2'
      } else if (this.editor.isActive('heading', { level: 3 })) {
        return 'Heading 3'
      } else {
        return 'Paragraph'
      }
    },
  },
  methods: {
    changeToggleParagraph() {
      this.toggleParagraph = !this.toggleParagraph
    },
    changeToggleTableAdd() {
      this.toggleTableAdd = !this.toggleTableAdd
    },
    changeToggleTableDelete() {
      this.toggleTableDelete = !this.toggleTableDelete
    },
    changeToggleTableTools() {
      this.toggleTableTools = !this.toggleTableTools
    },
    closeToggleParagraph() {
      this.toggleParagraph = false
    },
    closeToggleTableAdd() {
      this.toggleTableAdd = false
    },
    closeToggleTableDelete() {
      this.toggleTableDelete = false
    },
    closeToggleTableTools() {
      this.toggleTableTools = false
    },
    blurInput() {
      console.log('blur');
    },
    setFocused() {
      this.isFocus = true
    },
    setFocusedFalse() {
      this.isFocus = false
    },
    setContent() {
      let content = this.editor.getHTML()
      if (!('content' in this.editor.getJSON().content[0])) {
        content = ''
      }
      this.$emit('input', content)
    }
  },
  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2, 3],
          }
        }),
        Underline,
        Table.configure({
          resizable: false,
          cellMinWidth: 0,
        }),
        CharacterCount.configure({
          limit: this.limit,
        }),
        TableRow,
        TableHeader,
        TableCell,
      ],
      onUpdate: () => {
        // HTML
        const content = this.editor.isEmpty ? '' : this.editor.getHTML()
        const characters = this.editor.storage.characterCount.characters()
        // this.setContent()
        this.$emit('input', content)
        this.$emit('char', characters)
      },
      editorProps: {},
      editable: true,
      autoFocus: null,
      useBuiltInExtensions: true,
      disableInputRules: false,
      disablePasteRules: false,
      dropCursor: {},
      parseOptions: {},
      injectCSS: true,
      onInit: () => ({}),
      onTransaction: () => ({}),
      onFocus: () => ({}),
      onBlur: () => ({}),
      onPaste: () => ({}),
      onDrop: () => ({}),
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang="scss">

.height-tiptap-toolbar {
  height: 2.5rem;
}
.height-tiptap-input {
  padding: 0.5rem;
  min-height: 2.5rem;
}

.ProseMirror:focus {
  outline: none;
}
.ProseMirror {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  ul,ol {
    padding: 0 2rem;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  h1 {
    font-size: 1.5rem; /* 24px */
    line-height: 2rem;
  }
  h2 {
    font-size: 1.25rem; /* 20px */
    line-height: 1.75rem;
  }
  h3 {
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; 
  } 
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
  
    td,
    th {
      min-width: 1em;
      border: 2px solid #AAAAAA;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      font-size: 0.875rem;
  
      > * {
        margin-bottom: 0;
      }
    }
  
    th {
      font-weight: bold;
      text-align: left;
      background-color: #E9ECEF;
    }
  
    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: "";
      left: 0; right: 0; top: 0; bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }
  
    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  
    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
</style>